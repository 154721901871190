.projects {
  margin: 5rem auto;
}
.card:hover .card-body {
  transform: scale(1.05);
  transition: all 0.5s;
}
a.btn.btn-primary:hover {
  color: black !important;
}
