.contact-form h2,
.other-contact h2 {
  color: rgb(83, 83, 83);
}
.contact-form label {
  color: rgb(83, 83, 83);
}
.contact-form {
  margin-top: 2rem;
  padding-top: 2rem;
  margin-bottom: 5rem;
}
.fa-phone-square,
.fa-envelope-square {
  font-size: 3.5rem;
}
.other-contact-box {
  display: grid;
  justify-items: center;
  align-items: center;
}
.other-contact-box a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(83, 83, 83);
}
.btn.btn-primary:hover {
  background-color: white !important;
}
