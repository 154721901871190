.skill-section {
  display: grid;
  justify-items: center;
  margin: 2rem 0;
  padding: 2rem 0;
  margin-bottom: 5rem;
}
.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.skills h2 {
  color: rgb(83, 83, 83);
}
.design-skills,
.coding-skills,
.managing-skills {
  padding: 1rem;
  display: grid;
  justify-items: center;
}

.progress-bar {
  background-color: #ffd609;
  color: rgb(83, 83, 83);
  text-align: end;
}
.progress {
  width: 288px;
}
