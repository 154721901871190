.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  background-color: rgb(83, 83, 83);
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  color: white;
}
