.tiles {
  margin: 6rem 0 8rem 0;
  font-size: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tiles .tile {
  display: inline-block;
  margin: 10px;
  text-align: left;
  opacity: 0.99;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  border: black 2px solid;
}
.tiles .tile:before {
  content: "";
  background: #ffd609;
  width: 100%;
  height: 50%;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  transition-property: top, opacity;
  transition-duration: 0.3s;
}
.tiles .tile img {
  display: block;
  max-width: 300px;
  max-height: 300px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.tiles .tile .details {
  font-size: 16px;
  padding: 20px;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}
.tiles .tile .details span {
  display: block;
  opacity: 0;
  position: relative;
  top: 100px;
  transition-property: top, opacity;
  transition-duration: 0.3s;
  transition-delay: 0s;
}
.tiles .tile .details .title {
  line-height: 1;
  font-weight: 600;
  font-size: 2rem;
}
.tiles .tile .details .info {
  color: black;
  line-height: 1.2;
  margin-top: 5px;
  font-size: 16px;
}
.tiles .tile:focus:before,
.tiles .tile:hover:before,
.tiles .tile:focus span,
.tiles .tile:hover span {
  opacity: 1;
}
.tiles .tile:focus:before,
.tiles .tile:hover:before {
  top: 50%;
}
.tiles .tile:focus span,
.tiles .tile:hover span {
  top: 0;
}
.tiles .tile:focus .title,
.tiles .tile:hover .title {
  transition-delay: 0.15s;
}
.tiles .tile:focus .info,
.tiles .tile:hover .info {
  transition-delay: 0.25s;
}
