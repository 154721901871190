svg {
  color: rgb(83, 83, 83);
  padding: 10px;
}
svg:hover {
  color: #ffd609;
}
.fa-behance,
.fa-github,
.fa-linkedin {
  font-size: 3.5rem;
}
