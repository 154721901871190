.about {
  margin: 5rem auto;
}
.about-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.about-img {
  margin: 0 auto;
  border: 5px #ffd609 solid;
  border-radius: 5px;
}
.about-img img {
  height: 300px;
}
.about-text {
  max-width: 600px;
  display: flex;
}
.about-text p {
  display: flex;
  align-self: center;
  padding-top: 5px;
}
.motion-cv {
  margin: 50px 0;
  border: 2px solid rgb(83, 83, 83);
  border-radius: 5px;
}
.video-responsive {
  position: relative;
  display: block;
  width: 640px;
  overflow: hidden;
}

.video-responsive::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.video-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
h5 {
  text-align: center;
}
