.jumbotron {
  /* background-color: white; */
  display: grid;
  align-items: center;
  justify-items: center;
  background: none;
}
.banner-whole {
  margin: 2rem 0;
  padding: 2rem 0;
  height: 100vh;
  background: url("../../assets/pic/coding.gif") no-repeat fixed center center;
  background-color: #e3e3e3;
  /* background-size: 500% 100%;
  animation: gradient 200s ease infinite;
  box-sizing: border-box;
  margin: 0;
  padding: 2rem; */
}
.banner-text {
  background-color: #e3e3e3;
}
/* https://media.giphy.com/media/L8K62iTDkzGX6/source.gif */
